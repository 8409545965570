.follower-grid {
    animation: 1s ease-out 0s 1 SlideFromLeft;
    max-height: 90vh;
    height: 90vh;
    width: 100%;
    border: 1px solid $light;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .1);
        border-radius: 3px;
    }
}

.follower-card {
   
    border-radius: 10px;
    padding: 1rem;
    height: fit-content;
    background: $light;
    margin: 1rem;
    box-shadow: 2px 3px 5px rgba(0,0,0,.2);
    img{
        border-radius: 10px;
    }

    .follower-profile-thumb {
        width: 128px;
    }
}

@media(max-height:700px){
    .follower-grid{
        height:77vh;
    }
}