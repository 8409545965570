.nav-bar {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .3);

    p {
        font-weight: bold;
        margin: 0;

    }
}

.logo {
    width: 60px;
}

.search-bar {
    background: $dark;
    display: flex;
    border-radius: 10px;
    padding: 10px;

    .srch-inp {

        background: $dark;
        font-family: 'Livvic', sans-serif;
        width: 0;
        font-size: 1.4rem;
        border: none;
        transition: width 1s ease-in-out;

        &:focus {
            outline: none;
            width: 300px;
        }

    }

    &:hover {
        .srch-inp {
            width: 300px;

        }
    }

    .srch-btn {
        background: $dark;
        border: none;

        img {
            width: 36px;
        }
    }
}

@media(max-height:700px) {
    .nav-bar {
        padding:5px;
        font-size: 1rem;
        .logo {
            width: 40px;
        }

        .srch-btn {
            img {
                width: 24px;
            }
        }
    }
}